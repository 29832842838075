import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)'
            : 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          alignItems="center"
          useFlexGap
          sx={{ width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              fontFamily: 'NourdMedium',
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            HOME
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                fontFamily: 'NourdMedium',
                color: (theme) =>
                  theme.palette.mode === 'light'
                    ? 'primary.main'
                    : 'primary.light',
              }}
            >
              SAVVY
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ width: { sm: '100%', md: '80%' } }}
          >
            {/* Welcome to HomeSavvy! We recognize that buying a home is a
            significant undertaking. That's why we're here empowering you with
            the tools you need for enhanced oversight during the home
            verification process.
            <br />
            <br />
            We're dedicated to educating and safeguarding your finances,
            allowing you to buy with confidence. Early access to the HomeScore
            and HomeReport allows you to make informed decisions, saving both
            time and money. Count on us to assist in vetting properties,
            ensuring you negotiate effectively or walk away before it's too
            late. */}
            We create reports about active real estate listings to give buyers information they need to anticipate potential large scale issues before making an offer or being under contract.
          </Typography>
          <Divider />
        </Stack>
      </Container>
    </Box>
  );
}
