// import logo from './logo.svg';
import './App.css';
import LandingPage from './landing-page/LandingPage';
import LiveDemo from './live-demo/Dashboard'
// import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<LandingPage />}
        />
        <Route
          path="/livedemo"
          element={<LiveDemo />}
        />
      </Routes>
    </Router>
  );
}

export default App;
