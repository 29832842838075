import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const qandas = [
  {
    q: 'How will HomeSavvy obtain accurate real estate information for its platform?',
    a: 'HomeSavvy retrieves real estate data from State, County, and City records, updated daily for accuracy.',
  },
  {
    q: "What is HomeSavvy's approach to monetization beyond buyer fees?",
    a: 'HomeSavvy is exploring additional monetization avenues, including licensing our dataset, offering API access for third-party sites to display truncated reports (HomeScore), and providing bulk discounts through contractual pricing for brokers.',
  },
  {
    q: 'Why choose HomeSavvy over established real estate platforms like Zillow and Redfin?',
    a: 'HomeSavvy complements established platforms like Zillow and Redfin by focusing on the middle stages of property buying, offering insights crucial for decision-making on offers and property evaluation.',
  },
  {
    q: 'How does HomeSavvy plan to reach its revenue projections while avoiding market fluctuations?',
    a: 'For questions related to market strategy please reach out to noah@homesavvy.io',
  },
  {
    q: "What is HomeSavvy's go-to-market strategy to achieve its ambitious customer acquisition goals?",
    a: 'For questions related to market strategy please reach out to noah@homesavvy.io',
  },
  {
    q: 'How will HomeSavvy ensure the accuracy and reliability of its reports compared to competitors?',
    a: "HomeSavvy ensures superior data quality compared to competitors by focusing on specific municipalities and employing local contracted real estate experts to enhance our platform's accuracy and reliability.",
  },
  {
    q: 'What measures does HomeSavvy take to educate first-time buyers about often overlooked aspects of real estate transactions?',
    a: 'HomeSavvy initiates buyer education by highlighting flagged items in our initial report, encouraging discussions with buyer agents or local contractors. Future plans include offering detailed steps for DIY-oriented buyers seeking further guidance.',
  },
  {
    q: 'Is HomeSavvy considering partnerships or distribution channels to enhance its market reach?',
    a: 'For questions related to market strategy please reach out to noah@homesavvy.io',
  },
  {
    q: 'How does HomeSavvy address concerns about its reliance on one-time transactions for revenue?',
    a: 'For questions related to Finance please reach out to ellie@homesavvy.io',
  },
  {
    q: "What is HomeSavvy's plan for compiling real estate data from various sources and providing unique insights?",
    a: 'HomeSavvy employs software automation for data compilation and report generation. Our unique insights stem from established criteria provided by vetted real estate brokers collaborating closely with developers to ensure consistent, high-quality recommendations.',
  },
  {
    q: 'Why does HomeSavvy choose to charge a fee for its services rather than integrating into real estate agent fees?',
    a: 'HomeSavvy opts to charge a fee for its services rather than integrating into real estate agent fees to avoid potential conflicts of interest. Our focus on providing direct value to buyers aligns with our monetization strategy.',
  },
  {
    q: 'Does HomeSavvy use AI or manual processes to gather and compile real estate data?',
    a: 'HomeSavvy currently uses industry professionals and developers to gather relevant data. In the future, we plan to leverage our proprietary dataset to develop predictive models using AI algorithms, enhancing the depth of insights provided to users.',
  },
  {
    q: 'How does HomeSavvy plan to establish its presence in the market without significant marketing efforts?',
    a: 'For questions realted to marketing please reach out to noah@homesavvy.io',
  },
  {
    q: 'Is HomeSavvy considering automating report generation in the future?',
    a: 'HomeSavvy is primarily focused on expanding and enhancing our automatic report generation capabilities. While relevant datasets from government agencies are publicly accessible, some effort is required to ensure high data quality and repeatability',
  },
];
export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    // console.log('This panel is clicked', panel);
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        {qandas.map((qandas, idx) => (
          <Accordion
            expanded={expanded === `panel${idx}`}
            onChange={handleChange(`panel${idx}`)}
            key={idx}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${idx}d-content`}
              id={`panel${idx}d-header`}
              key={idx}
            >
              <Typography
                component="h3"
                variant="subtitle2"
              >
                {qandas.q}
              </Typography>
            </AccordionSummary>
            <AccordionDetails key={idx}>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                {qandas.a}
                {/* <Link> support@homesavvy.io </Link> */}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
