import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';

function preventDefault(event) {
  event.preventDefault();
}

export default function Deposits() {
  return (
    <React.Fragment>
      <Title>Current Listing Price</Title>
      <Typography
        component="p"
        variant="h4"
      >
        $765,000
      </Typography>
      <Typography
        color="text.secondary"
        sx={{ flex: 1 }}
      >
        on 15 March, 2024
      </Typography>
      <Typography>
        <Link
          color="primary"
          href="#"
          onClick={preventDefault}
        >
          View Listing on Zillow
        </Link>
      </Typography>
      <Typography>
        <Link
          color="primary"
          href="#"
          onClick={preventDefault}
        >
          View Listing on Redfin
        </Link>
      </Typography>
    </React.Fragment>
  );
}
