import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
// import Button from '@mui/material/Button';
// import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import { Download } from '@mui/icons-material';
// import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    name: 'High Risk Property Example',
    occupation: '30/100 rating',
    testimonial: '',
    image: '/static/images/30_100_page_1.png',
    pdf: 'Property Address 34215 40th Ave S, Auburn, WA 98001.pdf',
  },
  {
    name: 'Low Risk Property Example',
    occupation: '80/100 rating',
    testimonial: '',
    image: '/static/images/80_100_page_1.png',
    pdf: 'Property Address 10529 SE 272nd Place unit 7, Kent, WA 98030.pdf',
  },
];

const report_location = '/static/reports/';

export default function ExampleReports() {
  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          color="text.primary"
        >
          Example Reports
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
        >
          See some examples of our short form reports
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
      >
        {userTestimonials.map((testimonial, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{ display: 'flex' }}
          >
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardMedia
                sx={{
                  height: 140,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
                image={testimonial.image}
                // onClick={}
              />
              <CardContent sx={{ opacity: 1 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                <CardActions>
                  <Link
                    href={report_location + encodeURIComponent(testimonial.pdf)}
                  >
                    View Full Report
                  </Link>
                  <IconButton aria-label="download">
                    <a
                      href={
                        report_location + encodeURIComponent(testimonial.pdf)
                      }
                      target="_blank"
                      download={testimonial.pdf}
                      rel="noreferrer"
                    >
                      <Download />
                    </a>
                  </IconButton>
                </CardActions>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
